<app-side-bar *ngIf="showSidebar"></app-side-bar>

<section class="bg-gray-50  py-3 sm:py-5" *ngIf="!plans_loading">
    <div class="px-4 mx-auto max-w-screen-2xl lg:px-12">
        <div class="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
            <div class="px-4 divide-y ">


                <!-- Action buttons -->
                <div
                    class="flex flex-col items-start justify-between py-4 space-y-3 md:flex-row md:items-center md:space-y-0">
                    <button (click)="createPlan()" type="button"
                        class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300  focus:outline-none ">
                        + Nuevo Plan
                    </button>

                </div>
            </div>



            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500  mb-3">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>

                            <th scope="col" class="px-4 py-3">
                                <input type="checkbox" value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                            </th>
                            <th scope="col" class="px-4 py-3">Plan</th>
                            <th scope="col" class="px-4 py-3">Visibilidad</th>
                            <th scope="col" class="px-4 py-3">Estatus</th>
                            <th scope="col" class="px-4 py-3">Cuotas</th>
                            <th scope="col" class="px-4 py-3">Frecuencia</th>
                            <th scope="col" class="px-4 py-3">Versión</th>
                            <th scope="col" class="px-4 py-3">Actualizado</th>
                            <th scope="col" class="px-4 py-3">Codigos Dinámicos </th>
                            <th scope="col" class="px-4 py-3">Creditos </th>

                            <th scope="col" class="px-4 py-3">Usuario</th>
                            <th scope="col" class="px-4 py-3"></th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let item of plans">


                        <tr class="border-b  hover:bg-gray-100 " >
                            <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                <input type="checkbox" value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                            </th>

                            <td class="px-4 py-2 cursor-pointer" (click)="editPlan(item)">
                                <div class="flex items-center text-primary-500 " >
                                    <span class="font-medium ">
                                        {{item.name}}
                                    </span>
                                </div>
                            </td>

                            <td class="px-4 py-2">
                                <div class="flex items-center">
                                    <span 
                                        class=" text-gray-400 rounded-md text-[12px] py-1 px-2 flex gap-1 items-center"
                                        [ngClass]="{
                                            'bg-blue-100': item.visibility == 'PRIVATE',
                                            'bg-gray-100': item.visibility !== 'PRIVATE'
                                        }"
                                    >
                                    <img *ngIf="item.visibility != 'PRIVATE'" src="../../../../../assets/icons/eye.svg" alt="">
                                    <img *ngIf="item.visibility == 'PRIVATE'" src="../../../../../assets/icons/clipboard.svg" alt="">
                                        {{visibilityTextUpdate(item.visibility)}}
                                    </span>

                                </div>
                            </td>

                            <td class=" px-4 py-2 font-medium text-gray-900 flex gap-1 items-center">
                                <div class="w-3 h-3 mr-2 "
                                    [ngClass]="item?.status ? 'bg-green-500 rounded-full' : 'bg-red-500 rounded-full'">
                                </div>
                                <span>{{item.status?'Activo':'Inactivo'}}</span>
                            </td>

                            <td class="px-4 py-2">
                                <span> {{quotasTextpdate(item)}}</span>
                            </td>

                            <td class="px-4 py-2">
                                <div class="flex items-center">

                                    <span class="font-medium text-gray-400 text-[12px]">
                                        {{frequencyLabelUpdate(item.payment_terms[0].frequency.type)}}
                                    </span>
                                </div>
                            </td>

                            <td class="px-4 py-2 font-medium text-gray-900 flex gap-1 items-center">
                                <div class="w-3 h-3 mr-2 bg-green-500 rounded-full">
                                </div>
                                <span>{{item.version.internal_code}}</span>
                            </td>

                            <td class="px-4 py-2">
                                <span>{{item.version.date_init_version | date}}</span>
                            </td>

                            <td class="px-4 py-2">
                              <span *ngIf="item.params.filters.dinamic_code">
                                <span *ngIf="item.params.filters.dinamic_code.person?.list.length > 0">
                                 {{getCodes(item.params.filters.dinamic_code.person.list)}}
                                </span>
                                <span *ngIf="item.params.filters.dinamic_code.person?.list.length == 0">
                                    <span>sin código</span>
                                </span>


                              </span>
                              <span *ngIf="!item.params.filters.dinamic_code"> sin código</span>
                            </td>
                            <td class="px-4 py-2">
                                <span >{{item.invoices }}</span>
                            </td>
                            <td class="px-4 py-2">
                                <span *ngIf="item.ejecutive">{{item.ejecutive.username }}</span>
                                <span *ngIf="!item.ejecutive">N/A</span>
                            </td>
                            <td class="px-4 py-2">
                                <div *ngIf="item.invoices  == 0">
                                    <button (click)="deletePlans(item._id)" class="ml-5 bg-red-600 w-5 h-5 rounded-md cursor-pointer">
                                        <i class="fas fa-trash-alt text-white"></i>
                                      </button>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span class="text-black pl-10" *ngIf="plans.length === 0">No se encontraron códigos creados</span>
            </div>

            <!-- Navigation -->
            <!-- <nav class="flex items-center  flex-wrap md:flex-row justify-between pt-4 px-4" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500  mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span class="font-semibold text-gray-900 ">1-10</span> of <span class="font-semibold text-gray-900">1000</span></span>
                <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a href="#" class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 ">Previous</a>
                    </li>
                    <li>
                        <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">1</a>
                    </li>
                    <li>
                        <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">2</a>
                    </li>
                    <li>
                        <a href="#" aria-current="page" class="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700">3</a>
                    </li>
                    <li>
                        <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">4</a>
                    </li>
                    <li>
                        <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">5</a>
                    </li>
                    <li>
                <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ">Next</a>
                    </li>
                </ul>
            </nav> -->
        </div>
    </div>
</section>

<div class="flex justify-center items-center w-full mt-10" *ngIf="plans_loading">
    <img src="../../../../../assets/public/images/loading.gif" alt="">
</div>