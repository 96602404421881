import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { PlanService } from './plan.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css'],
  providers: [DatePipe],
})
export class PlansComponent implements OnInit {
  plans: any;
  plans_loading: any;
  status_plans: any = true;
  plans_active: any;
  plans_inactive: any;
  showOld = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService,
    private _planService: PlanService
  ) {}

  ngOnInit(): void {
    this.getPlans();

   // debugger;
  }

  deletePlan =  async (item:any) => {

    try {
      await this._user.deletePlan({id_plan:item });
      this.getPlans();

      alertifyjs.success('eliminación exitosa');
      
    } catch (error:any) {
      
      alertifyjs.error(error?.error?.msg);

    }


  }

  getPlans = async (plans_loading = true) => {
    try {
      this.plans_loading = plans_loading;

      let plans = await this._user.getplansall({status:this.status_plans});
      let plansInvoices = (await this._user.getplansInvoices({}))?.res;


    
      this.plans =  plans?.res?.map((item:any)=>{
 
           item.invoices =   plansInvoices[item._id] || 0;
  
        return item;
  
       });


      this.plans_active =
        this.plans?.filter((item: any) => item.status == true)?.length > 0
          ? true
          : false;

      this.plans_inactive =
        this.plans?.filter((item: any) => item.status != true)?.length > 0
          ? true
          : false;



    } catch (error: any) {
      console.log(error);
    }
    this.plans_loading = false;
  };

  pocisionBottom = async (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };


  activePlan = async (code_plan: any) => {
    try {
      debugger;
      let resp = await this._user.activePlan({ code_plan });

      alertifyjs.success('activación exitosa');
      //  this.router.navigate([environment.loginRoute], {});
      this.getPlans(false);
    } catch (error: any) {
      alertifyjs.error('Error al activar plan');
      console.error(error);
    }
  };

  close() {
    this.offcanvasService.dismiss('');
    this.getPlans(false);
  }

  showPlan(plan: any, content: TemplateRef<any>) {
    this._planService.selectedPlan = plan;
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

