<section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="px-4 mx-auto max-w-screen-2xl lg:px-12">
      <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <div class="px-4 divide-y dark:divide-gray-700">
          <!-- Top section with summary -->
          <!-- <div class="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
            <div class="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-4 md:space-y-0">
              <h5>
                <span class="text-gray-500">All Users:</span>
                <span class="dark:text-white">1,356,546</span>
              </h5>
              <h5>
                <span class="text-gray-500">Projects:</span>
                <span class="dark:text-white">884</span>
              </h5>
            </div>
          </div> -->

          <!-- Action buttons -->
          <div class="flex flex-col items-start justify-between py-4 space-y-3 md:flex-row md:items-center md:space-y-0">
            <button (click)="toggleModal()" type="button" class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
              <i class="fas fa-plus mr-2"></i>
              <span class="font-bold">Nuevo código</span>
            </button>
            <!-- <div class="flex flex-col rounded-md shadow-sm space-y-2 md:space-y-0 md:flex-row" role="group">
              <button type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-t-md hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white">Suspend all</button>
              <button type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-gray-200 border-x-0 border-b hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white">Archive all</button>
              <button type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-md hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-primary-500 dark:focus:text-white">Delete all</button>
            </div> -->
          </div>
          <!-- Modal principal -->
              <div
              *ngIf="isModalOpen"
              class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div class="relative p-4 w-full max-w-2xl">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-800">
                  <!-- Encabezado del modal -->
                  <div class="flex justify-between items-center p-4 border-b dark:border-gray-600">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                      Crear un código
                    </h3>
                    <button
                      (click)="toggleModal()"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                    </button>
                  </div>

                  <form class="p-4" (ngSubmit)="serviceCode()">
                    <div class="grid gap-4 sm:grid-cols-2">
                      <div>
                        <label for="code" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Código<span class="text-red-500">*</span></label>
                        <input [(ngModel)]="newCode.code" type="text" name="code" id="code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ejem. MT-EMP-ADMIN">
                        <span class="text-red-500 text-sm" *ngIf="!newCode.code.trim() && globalError">Código requerido</span>
                      </div>
                      <div>
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre del código<span class="text-red-500">*</span></label>
                        <input [(ngModel)]="newCode.name" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ejem. Código de administradores">
                        <span class="text-red-500 text-sm" *ngIf="!newCode.name.trim() && globalError">Nombre del código requerido</span>
                      </div>
                      <div class="sm:col-span-2">
                        <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descripción</label>
                        <textarea [(ngModel)]="newCode.description" type="text" name="description" id="description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 h-44" placeholder="Ejem. Este es un código para los empleados administradores"></textarea>
                      </div>
                    </div>
                    <div class="flex items-center space-x-4 mt-4">
                      <button type="submit" class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-[100%]">
                        Agregar código
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              </div>
        </div>



        <!-- Table -->
        <div class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-3">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-4 py-3">Código</th>
                <th scope="col" class="px-4 py-3">Estatus</th>
                <th scope="col" class="px-4 py-3">Planes</th>
                <th scope="col" class="px-4 py-3">Persona</th>
                <th scope="col" class="px-4 py-3">Nombre</th>
                <th scope="col" class="px-4 py-3">Creado</th>
                <th scope="col" class="pr-32">Usuario</th>
                <th scope="col" class="px-4 py-3">Acción</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of codesList">
              <tr class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <span class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{{item?.code}}</span>
                </th>
                <td class="px-4 py-2">
                  <div class="flex items-center">
                    <div class="w-3 h-3 mr-2" [ngClass]="item?.status ? 'bg-green-500 rounded-full' : 'bg-red-500 rounded-full'"></div>
                    <span class="font-medium text-gray-900 dark:text-white">
                      {{item?.status ? 'Activo' : 'Inactivo'}}
                    </span>
                  </div>
                </td>
                <td class="pl-10">
                  <span>{{item.planCode}}</span>
                </td>
                <td class="pl-10">
                  <span>{{item?.personCode}}</span>
                </td>
                <td class="px-4 py-2 font-medium text-gray-900 dark:text-white">
                  <span>{{item?.name}}</span>
                </td>
                <td class="px-4 pyt-2">{{item?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                <td>
                  <span>{{item?.user?.name}}</span>
                  <span> {{item?.user?.lastName}}</span>
                </td>
                <td>
                  <button class="w-5 h-5 ml-5" (click)="updateStatus(item?.code, item?.status)">
                    <i *ngIf="item?.status" class="fas fa-lock-open text-gray-500"></i>
                    <i *ngIf="!item?.status"  class="fas fa-lock text-red-500"></i>
                  </button>
                  <button class="ml-5 bg-red-600 w-5 h-5 rounded-md" (click)="deleteCodeDynamic(item?.code)" *ngIf="item?.personCode === 0 && item?.planCode === 0">
                    <i class="fas fa-trash-alt text-white"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <span class="text-black pl-10" *ngIf="codesList.length === 0">No se encontraron códigos creados</span>
        </div>

        <!-- Navigation -->
        <nav class="flex flex-col items-start justify-between p-4 space-y-3 sm:flex-row sm:items-center sm:space-y-0" aria-label="Table navigation">
          <div class="flex items-center space-x-3">
            <label for="rows" class="text-xs font-normal text-gray-500 dark:text-gray-400">Rows per page</label>
            <select id="rows" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block py-1.5 pl-3.5 pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
              <option selected value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <div class="text-xs font-normal text-gray-500 dark:text-gray-400">
              <span class="font-semibold text-gray-900 dark:text-white">1-10</span>
              of
              <span class="font-semibold text-gray-900 dark:text-white">100</span>
            </div>
          </div>
          <ul class="inline-flex items-stretch -space-x-px mt-3 sm:mt-0">
            <!-- <li>
              <a href="#" class="flex text-sm w-20 items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
            </li>
            <li>
              <a href="#" class="flex text-sm w-20 items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>
  </section>
