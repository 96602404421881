import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plan } from './planModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  selectedPlan: any = null;
  base = `${environment.BASE_LA}/api`;
  constructor(public httpClient: HttpClient) {}

  createPlan(plan: Plan) {
    return this.httpClient.post(`${this.base}/plans`, plan);
  }

  updatePlan(plan: Plan) {
    return this.httpClient.put(`${this.base}/plans`, plan);
  }

  getAllPlans(plan: Plan) {
    return this.httpClient.get(`${this.base}/plans/all`);
  }

  getPlanByCode(code: string) {
    let params = new HttpParams().set('code_plan', code);
    return this.httpClient.get(`${this.base}/plans`, { params});
  }

  getCodes() {
    return this.httpClient.get(`${this.base}/code`);
  }
}
