import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @Output() showPlanDetails: EventEmitter<any> = new EventEmitter();
  @Output() newPlan: EventEmitter<any> = new EventEmitter();
  @Output() deletePlan: EventEmitter<any> = new EventEmitter();

  @Input() plans: any
  @Input() plans_loading: any;
  @Input() status_plans: any = true;
  @Input() plans_active: any;
  @Input() plans_inactive: any;
  showSidebar = false
  constructor() { }

  ngOnInit(): void {
    // List here
  }

  visibilityTextUpdate(text: string) {
    if (text == 'PRIVATE') {
      return 'Privado'
    } else {
      return 'Público'
    }
  }

  frequencyLabelUpdate(text: string) {
    switch (text) {
      case Frequency.MES:
        return 'Mensual (30d)'
      case Frequency.QUINCENAL:
        return 'Quincenal (15d)'
      case Frequency.SEMANA:
        return 'Semanal (7d)'
      default:
        return 'días continuos'
    }
  }

  quotasTextpdate(item: any) {
    if (item.payment_terms) {
      let list: any[] = item.payment_terms
      if (list.length > 1) {
        let text = ''
        list.forEach((element: any, index: number) => {
          if (index == list.length - 1) {
            text = text + ' ' + element.installments
          } else {
            text = text + ' ' + element.installments + ','
          }

        })
        return text
      } else {
        return list[0].name
      }
    } else {
      return 'Por definir'
    }


  }

  createPlan() {
    this.newPlan.emit(true)
  }


  deletePlans(id:any) {
    this.deletePlan.emit(id)
  }



  editPlan(item: any) {
    this.showPlanDetails.emit(item)
  }

  getCodes(list: any) {
    let codeText = ''
    list.forEach((item: string) =>{
      codeText = codeText + item + ' '
    })
    return codeText
  }
}

export enum Frequency {
  MES = "mes",
  QUINCENAL = "quicenal",
  SEMANA = "semana",
  DIAS_CONTINUOS = "dias_continuos",
}
