
import {
  Component,
  forwardRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';


import {
  mobiscroll,
  MbscDatetimeOptions,
  MbscFormOptions,
} from '@mobiscroll/angular';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { debug } from 'console';
@Component({
  selector: 'app-edit-data-base',
  templateUrl: './edit-data-base.component.html',
  styleUrls: ['./edit-data-base.component.css'],
  providers: [DatePipe]

})

export class EditDataBaseComponent implements OnInit {
  @Input() code_person: any;
  @Input() person: any;
  @Output() changeView = new EventEmitter<any>();
  @Output() editView = new EventEmitter<any>();
  @ViewChild('miInput') miInput: ElementRef | undefined;
  @Output() resetStatus = new EventEmitter<any>();
  @Input() estatus_approve: any;


  @ViewChild('miInput') miInputVariable: ElementRef | undefined;
  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  form: any;
  submit_disabled: any;

  country: any;
  city: any;
  state: any;
  type: any;

  errorMsg: any;
  loadForm: any;
  loadPerson_Address: any;
  section_transactions: any = [];
  accounts: any = [];
  section_complete: any;
  validation_account_number: any;
  banks: any = [];
  bank: any;

  formDataUpload: any = null;
  fileName = '';
  value = '';
  last_value = this.value;

  sessionUser: any;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";

  contact_phone_val_err:any;
  contact_email_val_err:any;

  contact_phone_val:any ='';
  contact_email_val:any='';

  loading:any;

  submit_disabled_contact:any;
  validate_contact:any;

  activeValidateTlf = false;
  dataValidateTlf:any;
  env = environment;
  date_base:any;
  date_base_string:any;



  mobileSettings: MbscDatetimeOptions = {
    display: 'bottom',
    themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
    display: 'bubble',
    touchUi: false
};



  constructor(
    private _common: CommonService,
    private formBuilder: FormBuilder,
    private _user: UserService,
    public _auth: AuthService,
    private datePipe: DatePipe

  ) {}



  ngOnInit(): void {

      this.sessionUser = this._auth.sessionUser;
      this.contact_phone_val = this.person?.constacts[0]?this.person?.constacts[0]?.contact:'' ;
      this.contact_email_val= this.person?.constacts[1]?this.person?.constacts[1]?.contact:'' ;

      this.contact_phone_val = this.contact_phone_val?.replace('+58', '');


      this.form = this.formBuilder.group({

      name: [this.person?.person?.name,[Validators.required]],
      lastName: [this.person?.person?.lastName,[Validators.required]],
      second_name: [this.person?.person?.second_name],
      second_lastName: [this.person?.person?.second_lastName],
      contact: [this.contact_phone_val,[Validators.required]],
      email: [this.contact_email_val, [Validators.required,Validators.pattern(this.email)]]
    });


    if(this.person?.person?.birth_date){

      this.date_base = new Date(this.person?.person?.birth_date);
      this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    }


   // this.getAccounts();
   // this.getBanks();
  }

  ngAfterViewInit() {
    if(this.miInput) {
      this.miInput.nativeElement.onpaste = function(e: any) {
        e.preventDefault();
      };
      this.miInput.nativeElement.oncopy = function(e: any) {
        e.preventDefault();
      };
    }
  }

  onChange = ($event: any) => {
    let target = $event.target as HTMLInputElement;
    let value = target.value;

    if (value) {
      if (value && value.length >= 4) {
        this.value = value;
        this.last_value = this.value;
      } else {
        this.value = this.last_value;
      }
    } else {
      this.value = this.last_value;
    }

    this.form.patchValue({
      account_number: this.value,
    });
  };

  reset() {
    this.formDataUpload = null;
    this.fileName = '';
  }

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;
    }
  };


  edit_account = async (payment_method_customer: any) => {
    this.editView.emit({
      view: 6,
      id: payment_method_customer,
    });
  };


  submit = async () => {
    const data = this.form.getRawValue();
    console.log(data.account_number);
    console.log(this.bank.bankCode);
    if (this.form.valid) {
      if(data.account_number.toString().substring(0,  4) === this.bank.bankCode){
        this.submit_disabled = true;


        let params = {
          code_person: this.code_person,
          code_bank: data.bank,
          currency: data.currency,
          payment_method: data.payment_method,
          account_type: data.account_type,
          account_number: data.account_number,
          user: this.sessionUser.uid
        };

        const formData = new FormData();

        if (this.formDataUpload) {
          formData.append('archivo', this.formDataUpload);
        }

        formData.append('params', JSON.stringify(params));

        try {
          let resp = await this._common.setPayment_method(formData);
          // let resp = await this._common.setPayment_method(params);
          alertifyjs.success('Registro exitoso');

          this.form = this.formBuilder.group({
            bank: ['', [Validators.required]],
            currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
            payment_method: ['640b83e26522a425711e006f', [Validators.required]],
            account_type: ['01', [Validators.required]],
            account_number: ['', [Validators.required]],
          });

          this.reset();



          this.resetStatus.emit(true);

          this.submit_disabled = false;
        } catch (error: any) {
          const params = error?.error;
          if (params) {
            this.errorMsg = params?.msg;
          }
          alertifyjs.error(this.errorMsg);
          this.submit_disabled = false;
        }
      } else {

        this.form.patchValue({
          account_number: this.bank.bankCode,
        });
        this.submit_disabled = false;
        alertifyjs.error('Los primeros 4 dígitos del número de cuenta no son válidos para una cuenta del banco seleccionado.');
      }
    }
  };

  setChange(View: any) {
    this.changeView.emit(View);
  }

  validate_account_number = () => {
    let campo = this.form.controls.account_number.value;

    if (campo.length == 20) {
      this.validation_account_number = true;
    } else {
      this.validation_account_number = false;
    }
  };


showValidateTelNo = () =>{
  this.loading = true;
  let contact =  this.form.getRawValue()?.contact;
  this.dataValidateTlf = `+58${contact}`;

  this.next2();

}



showValidateTel = async() =>{


  this.loading = true;
  let contact =  this.form.getRawValue()?.contact;
  let email  =  this.form.getRawValue()?.email;


 let dataValidateTlf = `+58${contact}`;

 this.contact_phone_val_err = false;
 this.contact_email_val_err = false;

 try {



 let contact_phone_resp = await this.getcontact(dataValidateTlf);
 let contact_phone_val = contact_phone_resp?.resp;

 let valid1 = contact_phone_val && (contact !=  this.contact_phone_val );
 if(valid1){

  this.contact_phone_val_err = true;

 }else{

  this.contact_phone_val_err = false;

 }


 let contact_email_resp = await this.getcontact(email);
 let contact_email_val = contact_email_resp?.resp;


 let valid2 = contact_email_val  && (email !=  this.contact_email_val ) ;

 if(valid2){
  this.contact_email_val_err = true;

 }else{

  this.contact_email_val_err = false;
// aqui va la prueba

 }

 if(!valid1 && !valid2){
 // contact = "+584241724412";

 this.activeValidateTlf = true;
 this.dataValidateTlf = `+58${contact}`;


 this.next2();

}

this.loading = false;

} catch (error) {
 this.loading = false;

}


}

getcontact = async (contact:any) => {


  try {
    const data =  {
      contact
      }

  return this._user.contact(data);


  } catch (error) {
    console.error(error);

}
}


verificatedEmail = async (contact:any) => {


try {
const data =  {
  contact
  }

return this._user.verificatedEmailPost(data);


} catch (error) {
console.error(error);

}
}


closeValidateTlf = (e:any) => {

this.activeValidateTlf = false;
//this.step = 2;


}

dateSet(e: any) {

  let date_base =  e.valueText;

  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;

}


next2 = async (validate:any = false) =>  {

  const data = this.form.getRawValue();




     try {

    let contact = [
      { type_contact:"PHONE_CELL",
        verificated_contact:validate,
        contact: `+58${data?.contact}`,
        contact_oll: `+58${this.contact_phone_val}`
      },
      { type_contact:"EMAIL",
        verificated_contact:false,
        contact:data?.email,
        contact_oll: this.contact_email_val
      }
    ]

    let {name,lastName,second_name,second_lastName} = data;

    let params = {

        name,
        lastName,
        second_name,
        second_lastName,
        code_person:this.code_person,
        contact:contact,
        birth_date:this.date_base_string,
    }

    debugger

   let update_data =  await this._user.update_person(params);
           alertifyjs.success('Actualización exitosa');
             this.setChange(1);


   this.loading = false ;

  } catch (error) {

    this.loading = false;

    alertifyjs.error('Error al actualizar');

    console.error(error);

  }

}



}
