import { Component, OnInit } from '@angular/core';
import { debug, error } from 'console';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.css']
})
export class CodesComponent implements OnInit {

  codesList:any[] = [];
  isModalOpen:boolean = false;
  newCode:any = {code: '', name: ''}
  formEmpty:any;
  globalError:boolean = false;

  constructor(
        private _user: UserService,
  ) { }

  ngOnInit(): void {
    this.consultAllCodes();
  }

  serviceCode() {
    if (!this.newCode.code || !this.newCode.name) {
      this.globalError = true;
      return;
    }

    let params = {
      code: this.newCode.code.trim(),
      name: this.newCode.name.trim(),
    };

    if (params.code) {
      this._user.allCodes(params).then(response => {
        alertifyjs.success(`Código agregado exitosamente`);
        this.toggleModal();
        this.consultAllCodes();
      }).catch(error => {
        console.error('Error:', error);
        alertifyjs.error(`El código ya existe`);
      });
    }
  }

 async consultAllCodes(){
    try {
      const response = await this._user.getAllCodes();
      if (response && Array.isArray(response.data)) {
        this.codesList = response.data;
      } else {
        this.codesList = [];
      }
    } catch (error) {
      console.error('Error en los codigos:', error);
    }
  }

  async updateStatus(code:string, changeStatus:boolean){
    try {
      let newStatus = !changeStatus;
      const response = await this._user.updateCodeStatus(code, newStatus)
      if(response){
        alertifyjs.success(`Estatus cambiado exitosamente`);
        const codeToUpdate = this.codesList.find(item => item.code === code);
        if (codeToUpdate) {
            codeToUpdate.status = newStatus;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteCodeDynamic(code:string){
    try {
      this._user.deleteCode(code).then(response => {
        if(response){
          alertifyjs.success(`Código eliminado exitosamente`);
          this.codesList = this.codesList.filter(item => item.code !== code)
        }
      }).catch(error => {
        console.error('Error:', error);
        alertifyjs.error(`Error al eliminar el codigo`);
      });

    } catch (error) {
      throw error;
    }
  }

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
    this.resetForm();
  }

  resetForm(){
    this.newCode = {code: '', name: ''}
    this.globalError = false;
  }

}
