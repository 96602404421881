<div class="mt-md-3 container-fluid">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">
                        <button *ngIf="view != 1" (click)="closeInstallment()" type="button" class="btn-close float-start " aria-label="Close"></button>

                        <i class="  fas fa-user"></i> Compras financiadas
                    </h5>

                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                    </div>


                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
                    </div>




                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>

                        <div class="text-center mt-2">
                            <div>


                                <button *ngIf="!env?.production" (click)="restClient()" class="btn  btn-outline-dark btn-sm ">
                                    <span >Eliminar compras financiadas</span>
                                </button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-outline-primary mt-2 btn-sm" [routerLink]="['/console/credit-save']" (click)="setIdentity()"> Nueva compra financiada</button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row rmt-2" *ngIf="view == 1">
                    <div class="col-12  col-md-8 offset-md-2">


                        <ng-container *ngFor="let item of resp_invoiceCredits">

                            <!-- <pre>{{item | json}}</pre> -->

                            <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                                <div class="row mt-2  row-k">
                                    <div class="col-12">
                                        <div class="small text-secondary">Compra financiada total</div>
                                        <h5 class="fw-bolder text-dark"> {{item?.totalAmount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></h5>
                                    </div>
                                    <div class="col-12">
                                        <div class="small text-secondary">Fecha de aprobación</div>
                                        <div class=" text-dark"> {{item?.dueDate | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                    </div>

                                    <div class="col-6">
                                        <div class="small text-secondary">ID Transaccion</div>
                                        <div class=" text-dark"> {{item?.identifier }}</div>
                                    </div>


                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">ID documento</div>
                                        <div class=" text-dark"> {{item?.merchantNumber }}</div>
                                    </div>


                                    <div class="col-6 ">
                                        <div class="small text-secondary">Tienda</div>
                                        <div class=" text-dark"> {{item?.provider_transaction?.shop }}</div>
                                    </div>


                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">Caja</div>
                                        <div class=" text-dark"> {{item?.provider_transaction?.cash_register }}</div>
                                    </div>

                                    <div class="col-12 ">
                                        <div class="small text-secondary ">C.I. Cajero</div>
                                        <div class=" text-dark"> {{item?.provider_transaction?.user }}</div>
                                    </div>


                                    <div class="col-6">
                                        <div class="small text-secondary">Plan</div>
                                        <div class="text-dark">{{item?.plan?.name}} </div>
                                    </div>
                                    <div class="col-6  text-end ">
                                        <div class="small text-secondary">Estatus</div>
                                        <div [ngClass]="{
                                        'text-success': item?.payment_status?.code == 'PaymentComplete',
                                        'text-dark': item?.payment_status?.code == 'PaymentPending',
                                        'text-warning': item?.payment_status?.code == 'PaymentTrash'
                                    }" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.payment_status?.name}} </div>

                                    </div>


                                    <div class="col-12" (click)="getPersonInstallmentsInvoiceCredits(item)">
                                        <div class="small text-secondary">Cuotas </div>
                                        <div>{{item?.plan?.number_installment?item?.plan?.number_installment: item?.plan?.min_number_installment}}

                                            <a class="text-primary cursor-pounter float-end">Ver cuotas</a></div>
                                    </div>
                                    <div class="col-6 ">
                                        <div class="small text-secondary">Monto inicial</div>
                                        <div class=" text-dark">{{item?.minimumPaymentDue | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                                    </div>

                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Monto financiado</div>
                                        <div class=" text-dark">{{item?.amount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>

                                    </div>


                                    <div class="col-6 ">
                                        <div class="small text-secondary">Monto pendiente</div>
                                        <div class=" text-dark">{{item?.dueBalance | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                                    </div>

                                    <div class="col-12 ">
                                        <div class="">

                                            <button (click)="register_netable_payment(item.code)" *ngIf="isNeteable[item.code] == true && item?.dueBalance > 0" type="button" class="btn btn-outline-danger mt-4">Saldar monto {{item?.dueBalance | currency:'USD':'': '1.2-2'}} USD </button>

                                        </div>
                                    </div>
                                    <div class="col-12" >

                                        <div *ngIf="_auth.IsPermitid(['OWNER']) &&   item?.payment_status?.code  !== 'PaymentTrash'" class="float-end">

                                            <a  class="text-danger cursor-pounter float-start" (click)="anularCompra(item)">Anular Compra
                                            </a>
                                        </div>
                                        <div>
                                          <button type="button" class="btn btn-outline-primary  mb-3" *ngIf="item?.params?.pagare?.url" (click)="downloadPdf(item?.params?.pagare?.url)">Descargar pagaré</button>
                                          <!-- <button type="button" class="btn btn-outline-primary  mb-3" *ngIf="item?.payment_status.code !== 'PaymentTrash' && item?.params?.pagare?.url" (click)="downloadPdf(item?.params?.pagare?.url)">Descargar pagaré</button> -->
                                        </div>

                                        <!-- <pre>{{pruebaFicticia | json}}</pre> -->

                                        <div>
                                            <span class="text-dark" *ngIf="item?.params?.pagare?.accepted === true">Aprobación de pagaré: <b>Aceptado</b></span><br>
                                            <span class="text-dark" *ngIf="item?.params?.pagare?.accepted === false">Aprobación de pagaré: <b>No aceptado</b></span><br>
                                            <span class="text-dark" *ngIf="item?.params?.pagare?.accepted !== false && item?.params?.pagare?.accepted !== true">Aprobación de pagaré: <b>Por aceptar</b></span><br>
                                        </div>
                                        <div>
                                          <span class="text-dark" *ngIf="item?.params?.pagare?.date_accepted">Fecha de aprobación: <b>{{item?.params?.pagare?.date_accepted | date: 'dd/MM/yyyy'}}</b></span>
                                        </div>


                                        <!-- <pre>{{item | json}}</pre> -->

                                    </div>

                                </div>
                            </div>


                        </ng-container>
                    </div>

                </div>

                <div class="row rmt-2" *ngIf="view == 2">
                    <div class="col-12  col-md-8 offset-md-2">
                        <div *ngIf="installments && view == 2" class="text-center">
                            <div class="text-secondary">---</div>

                            <div class="small text-secondary mt-2">ID Transaccion</div>
                            <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>
                            <div class="small text-secondary mt-2">Total</div>
                            <div class=" text-dark">{{invoiceSelected?.totalAmount | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                            <div class="small text-secondary mt-2">Monto pendiente</div>
                            <div class=" text-dark">{{invoiceSelected?.dueBalance | currency:'USD':'': '1.2-2'}} <span class="small">USD</span></div>
                            <div class="mt-2 fw-bold">Cuotas {{installments?.length}}</div>

                        </div>


                        <div *ngFor=" let item of installments;let i = index" class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm">

                           <!-- <pre>{{ item.params.url_pagare | json }}</pre> -->

                            <div class="row mt-2 row-k">
                                <div class="col-12 mt-2">

                                    <h5 class="fw-bolder text-dark"> {{ item?.installMent ?.order
                                        < 1 ? 'Inicial': 'cuota '+item?.installMent ?.order}}</h5>
                                </div>
                                <div class="col-6">
                                    <div class="small text-secondary">Total</div>
                                    <div class=" text-dark"> {{item?.installMent ?.amount | currency:'USD':'': '1.2-2'}} USD</div>

                                    <div class="text-success small" *ngIf="item?.installMent?.payment_status_remante_base">
                                        -{{item?.installMent?.total_remaining_base}} USD
                                    </div>

                                    <div class="text-danger small" *ngIf="item?.installMent?.payment_status_remante_next">
                                        +{{item?.installMent?.total_remaining_next}} USD
                                    </div>

                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>
                                    <div *ngIf="!item?.installMent?.payment_status_remante_base" [ngClass]="{'text-success': item?.installMent ?.payment_status?.code == 'PaymentComplete','text-dark': item?.installMent ?.payment_status?.code != 'PaymentComplete'}" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.installMent ?.payment_status?.name}} </div>

                                    <div *ngIf="item?.installMent?.payment_status_remante_base" class="text-success">Pago Completado</div>

                                </div>
                                <div class="col-12 ">
                                    <div class="small text-secondary">Fecha</div>
                                    <div class=" text-dark"> {{item?.installMent ?.date | date: 'dd/MM/yyyy'}}</div>

                                </div>

                                <div class="col-12 ">
                                    <a *ngIf="item?.installMent ?.payment_status?.code == 'PaymentComplete'" (click)="getPersonInstallmentpayment(item?.installMent )" class="text-primary cursor-pounter float-end">Ver pagos</a>

                                    <div class="small text-secondary">Fecha de Pago</div>
                                    <div class=" text-dark"> {{item?.installMent ?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>


                                </div>
                                <div class="col-6 ">
                                    <div class="small text-secondary">Pagada</div>
                                    <div class=" text-dark"> {{ (item?.installMent ?.amount - item?.installMent ?.dueBalance ) | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <!-- <div class="col-6 text-end">
                                    <div class="small text-secondary">Restante</div>
                                    <div class=" text-dark"> {{item?.installMent ?.dueBalance | currency:'USD':'': '1.2-2'}} USD</div>
                                </div> -->

                                <div class="col-6 text-end" *ngIf="item?.totalpaymentReference  > 0">
                                    <div class="small text-secondary">Total (Por conciliar)
                                    </div>
                                    <div class=" text-dark"> {{item?.totalpaymentReference | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <!-- <div class="col-6  text-end" *ngIf="item?.totalpaymentReference > item?.installMent ?.amount">
                                    <div class="small text-secondary"> Total exedente (Por conciliar ) </div>
                                    <div class=" text-dark"> {{ (item?.totalpaymentReference - item?.installMent ?.dueBalance) | currency:'USD':'': '1.2-2'}} USD</div>
                                </div> -->

                            </div>

                            <div class="row mt-2 row-k" *ngIf="item?.payments?.length > 0 || item?.paymentReference?.length > 0">

                                <div class="fw-bold text-secondary">Pagos</div>

                                <ng-container class="mt-3" *ngFor=" let item2 of item?.payments ;let i = index">

                                    <div class="col-6 ">
                                        <div class="small ">

                                            <span>
                                               {{item2?.payment?.paymentMethodId}}
                                            <div class=" text-secondary small">  {{item2?.payment?.code}}</div>


                                            </span>

                                        </div>

                                    </div>
                                    <div class="col-6  text-end small">
                                        <div class=" text-dark "> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        <div class="text-success" *ngIf="item2?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>

                                        <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo()" class="badge text-bg-warning">Por conciliar</span>
                                        </div>
                                    </div>

                                </ng-container>




                            </div>

                            <!-- installmentNexPayment == item?.installMent?.code && -->

                            <div *ngIf="!item?.installMent?.payment_status_remante_base && !item?.installMent?.payment_status_remante_next">

                                <div *ngIf=" (( item?.installMent ?.total_dueBalance ) > 0) &&  invoiceSelected?.payment_status?.code != 'PaymentTrash' " class="col-12 text-end mt-3">
                                  <span class="badge text-bg-warning">{{ (item?.installMent ?.total_dueBalance) | currency:'USD':'': '1.2-2'}} USD faltantes</span>
                                </div>

                            </div>

                            <div *ngIf="item?.installMent?.payment_status_remante_next">

                                <div *ngIf=" (item?.installMent ?.total_dueBalance_next  > 0) &&  invoiceSelected?.payment_status?.code != 'PaymentTrash' " class="col-12 text-end mt-3">
                                    <span class="badge text-bg-warning">{{ (item?.installMent ?.total_dueBalance_next) | currency:'USD':'': '1.2-2'}} USD faltantes</span>

                                </div>

                            </div>

                        </div>


                    </div>

                </div>

                <div class="row rmt-2" *ngIf="view == 3">
                    <div class="col-12  col-md-8 offset-md-2">
                        <div *ngIf="installments" class="text-center">
                            <div class="text-secondary">---</div>
                            <div class="small text-secondary mt-2">ID Transaccion</div>
                            <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>

                            <div class="fw-bold"> Pago {{ installMentSelected?.order
                                < 1 ? 'Inicial': 'Cuota '+ installMentSelected?.order }} </div>

                            </div>



                            <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let payment of payment_base">

                                <div class="row mt-2 row-k">
                                    <div class="col-6 mt-2">
                                        <h5 class="fw-bolder text-dark"> Pago</h5>
                                    </div>
                                    <div class="col-8 mt-2  ">
                                        <div class="small text-secondary">Código</div>
                                        <div class=" text-dark small"> {{payment?.payment?.code}}</div>
                                    </div>
                                    <div class="col-4   ">

                                        <div class="text-end ms-auto">
                                            <div class="small text-secondary">Conciliación</div>

                                            <div class=" small text-success" [ngClass]="{'text-success': payment?.payment?.typeTransaction == 'AUTO','text-secondary': payment?.payment?.typeTransaction != 'AUTO'}"> {{payment?.payment?.typeTransaction == 'AUTO'? 'Auto':'Manual'}}</div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="small text-secondary">Monto total</div>
                                        <div class=" text-dark"> {{payment?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>
                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Estatus</div>
                                        <div class="text-success">Pago conciliado </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="small text-secondary">Fecha</div>
                                        <div class=" text-dark"> {{payment?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                    </div>

                                    <div class="col-6 ">
                                        <div class="small text-secondary">Forma de Pago</div>
                                        <div class=" text-dark" [title]="payment?.payment?.payment_method?.description"> {{payment?.payment?.payment_method?.name}}</div>

                                    </div>
                                    <div class="col-6  text-end">
                                        <div class="small text-secondary">Número de confirmación</div>
                                        <div class=" text-dark"> {{payment?.payment?.paymentMethodId}}</div>
                                    </div>

                                </div>


                                <div class="fw-bold text-secondary mt-3 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">Voucher</div>


                                <div class="text-dark  fw-bold fz-14 mb-2" *ngIf="payment?.payment?.responseAutoConfirm?.descripcion">
                                    {{payment?.payment?.responseAutoConfirm?.descripcion}}
                                </div>

                                <div class="row" *ngIf="payment?.payment?.responseAutoConfirm?.voucher">
                                    <div class="col-12 col-md-6">
                                        <div class="voucher p-2 text-dark text-uppercase"  [innerHTML]="payment?.payment?.responseAutoConfirm?.voucher">

                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-3 row-k" *ngIf="payment?.installMentsPayment?.length > 0">


                                    <div class="fw-bold text-secondary col-12 mt-2">Distribución</div>
                                    <ng-container *ngFor=" let item of payment?.installMentsPayment ;let i = index">
                                        <div class="col-8">
                                            <div class="small text-primary">{{ item?.installMent?.order
                                                < 1 ? 'Inicial': 'Cuota '+ item?.installMent?.order }} </div>
                                                    <div class="small text-secondary small">{{item?.installMent?.code }}</div>

                                            </div>
                                            <div class="col-4 text-end ">
                                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>

                                    </ng-container>

                                    </div>

                                    <!-- <pre>{{ payment?.paymen_categ_concept_deduc | json }}</pre> -->

                                    <div class="row row-k" *ngIf="payment?.paymen_categ_concept_deduc?.length > 0">

                                        <ng-container *ngFor=" let item of payment?.paymen_categ_concept_deduc ;let i = index">
                                            <div class="col-8" *ngIf="item?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                                <div class="small text-primary">{{item?.category_concept_deduction?.concept_deduction?.name}}
                                                    <span class="text-secondary" *ngIf="item?.category_concept_deduction.isPorcent">{{item?.category_concept_deduction?.amount}}%</span> </div>
                                            </div>
                                            <div class="col-4 text-end " *ngIf="item?.category_concept_deduction?.concept_deduction?.name  == 'IGTF'">
                                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>

                                        </ng-container>

                                    </div>

                                </div>


                            </div>

                        </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_invoiceCredits?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron Compras financiadas</div>
                </div>
            </div>

            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


            <ng-container *ngIf="loading_person || loading">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>


            </div>
            </div>

        </div>
